import axiosInstance from "./Api";

export default {
  async getStudentsByApplicationStatus(payload) {

    return await axiosInstance().get(
      `/operations-manager/status-application-students`, { params: payload }
    );
  },

  async getStudentsByVisaStatus(payload) {
    return await axiosInstance().get(`/operations-manager/status-visa-students`, { params: payload });
  },

  async getAllCounselors() {
    return await axiosInstance().get(`/operations-manager/all-counselors`);
  },


  async getAllOperations() {
    return await axiosInstance().get(`/operations-manager/all-operations`);
  },

  async getAllVisa() {
    return await axiosInstance().get(`/operations-manager/all-visa`);
  },

};
